import React, { useState } from 'react';
import axios from 'axios';

const TextForm = () => {
  const [textInput, setTextInput] = useState('');
  const [destination, setDestination] = useState('');
  const [serverResponse, setServerResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [mediaUrl, setMediaUrl] = useState('');
  const [uploading, setUploading] = useState(false);
  const [faqQuestion, setFaqQuestion] = useState('');
  const [faqAnswer, setFaqAnswer] = useState('');

const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
        const storedUser = localStorage.getItem('user');
        if (!storedUser) {
            console.error('User data not found in local storage');
            return;
        }

        const { fullName } = JSON.parse(storedUser);
        let endpoint = '';
        let data = { createdBy: fullName };

        if (destination === 'blog') {
            endpoint = 'https://ampcds.onrender.com/jobs/blogs';
            data = { ...data, title, body: textInput, picture: mediaUrl }; // Add 'body'
        } else if (destination === 'sms') {
            endpoint = 'https://ampcds.onrender.com/jobs/send-sms';
            data = { ...data, body: textInput };
        } else if (destination === 'subscribed') {
            endpoint = 'https://ampcds.onrender.com/jobs/send-sms-sub-users';
            data = { ...data, body: textInput };
        } else if (destination === 'faqs') {
            endpoint = 'https://ampcds.onrender.com/api/faqs';
            data = { ...data, question: faqQuestion, answer: faqAnswer };
        }

        const response = await axios.post(endpoint, data);
        setServerResponse(response.data);
        // Clear fields after submission
        setTextInput('');
        setTitle('');
        setMediaUrl('');
        setFaqQuestion('');
        setFaqAnswer('');
    } catch (error) {
        console.error('Error submitting text:', error);
        setServerResponse({ error: 'Failed to submit' });
    } finally {
        setIsLoading(false);
    }
};


  const handleMediaUpload = (event) => {
    setUploading(true);
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setMediaUrl(reader.result);
      setUploading(false);
    };

    if (file) {
      reader.readAsDataURL(file); // This will encode the file as a base64 URL
    }
  };

  const renderBlogFields = () => {
    if (destination === 'blog') {
      return (
        <div style={{ width: '100%' }}>
          <input
            type="text"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            placeholder="Enter title..."
            required
          />
          <input
            type="file"
            accept="image/*,video/*"
            onChange={handleMediaUpload}
            required
          />
          {uploading ? <p>Uploading media...</p> : mediaUrl && <p>Media uploaded successfully!</p>}
        </div>
      );
    }
    return null;
  };

  const renderFaqFields = () => {
    if (destination === 'faqs') {
      return (
        <div style={{ width: '100%' }}>
          <input
            type="text"
            value={faqQuestion}
            onChange={(event) => setFaqQuestion(event.target.value)}
            placeholder="Enter FAQ question..."
            required
          />
          <textarea
            value={faqAnswer}
            onChange={(event) => setFaqAnswer(event.target.value)}
            placeholder="Enter FAQ answer..."
            required
            style={{ width: '100%', borderRadius: '8px', height: '60px' }}
          />
        </div>
      );
    }
    return null;
  };

  const renderTextInput = () => {
    // Show message body field only for blog, SMS, and subscribed
    if (destination !== 'faqs') {
      return (
        <textarea
          value={textInput}
          onChange={(event) => setTextInput(event.target.value)}
          placeholder="Message Body"
          required
          style={{ width: '100%', borderRadius: '8px', height: '60px' }}
        />
      );
    }
    return null;
  };

  const renderServerResponse = () => {
    if (serverResponse) {
      if (typeof serverResponse === 'string') {
        return <p>{serverResponse}</p>;
      } else if (typeof serverResponse === 'object') {
        return (
          <div>
            {Object.keys(serverResponse).map((key) => (
              <p key={key}><strong>{key}:</strong> {serverResponse[key]}</p>
            ))}
          </div>
        );
      }
    }
    return null;
  };

  return (
    <div style={{ width: '90%', textAlign: 'left', marginLeft: '10px', marginTop: '90px' }}>
      <h1 style={{ textAlign: 'center' }}>Create New PR</h1>
      <form onSubmit={handleSubmit}>
        <select
          value={destination}
          onChange={(event) => setDestination(event.target.value)}
          required
          style={{ marginBottom: '10px' }}
        >
          <option value="">Send To:</option>
          <option value="blog">Blog</option>
          <option value="sms">SMS</option>
          <option value="subscribed">Subscribed Users</option>
          <option value="faqs">FAQs</option>
        </select>
        {renderBlogFields()}
        {renderFaqFields()}
        {renderTextInput()}
        <button type="submit" disabled={isLoading || uploading} style={{ backgroundColor: 'green' }}>
          {isLoading ? 'Submitting...' : 'Submit'}
        </button>
      </form>
      {renderServerResponse()}
    </div>
  );
};

export default TextForm;
