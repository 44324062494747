import React, { useState } from 'react';
import '../styles/BusinessCentre.css';
import axios from 'axios';

const BusinessCardForm = () => {
    const [serviceRendered, setServiceRendered] = useState('');
    const [serviceDescription, setServiceDescription] = useState('');
    const [currentLocation, setCurrentLocation] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [minUnits, setMinUnits] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [isFormVisible, setIsFormVisible] = useState(true); // State to manage form visibility

    const services = [
        'All',
        'Accounting',
        'Advertising',
        'Architectural Services',
        'Automotive Repair',
        'Bakery Services',
        'Barber Services',
        'Beauty Services',
        'Carpentry',
        'Catering',
        'Cleaning Services',
        'Construction',
        'Consulting',
        'Counseling',
        'Dentistry',
        'Electrical Services',
        'Event Planning',
        'Financial Planning',
        'Fitness Training',
        'Graphic Design',
        'Hairdressing',
        'Healthcare Services',
        'Home Inspection',
        'Interior Design',
        'IT Services',
        'Landscaping',
        'Legal Services',
        'Locksmith',
        'Marketing',
        'Masonry',
        'Moving Services',
        'Painting',
        'Pest Control',
        'Pet Care',
        'Photography',
        'Plumbing',
        'Real Estate',
        'Security Services',
        'Software Development',
        'Tailoring',
        'Tax Preparation',
        'Translation Services',
        'Tutoring',
        'Veterinary Services',
        'Web Development',
        'Writing and Editing',
        'Yoga Instruction',
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setSuccessMessage('');

        const userId = JSON.parse(localStorage.getItem('user'))?._id;
        const businessCardData = {
            userId,
            serviceRendered,
            serviceDescription,
            currentLocation,
            businessName,
            minUnits,
        };

        try {
            await axios.post('https://ampcds.onrender.com/api/update-business-fields', businessCardData);
            setSuccessMessage('Business card submitted successfully!');
            // Hide form
            setIsFormVisible(false);
            // Clear inputs
            setServiceRendered('');
            setServiceDescription('');
            setCurrentLocation('');
            setBusinessName('');
        } catch (error) {
            console.error('Error submitting business card:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="business-card-form-container" style={{ marginBottom: '90px' }}>
            {isFormVisible && ( // Conditionally render the form based on isFormVisible
                <>
                    <h2 className="form-title">Submit Your Business Card</h2>
                    <form className="business-card-form" onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="serviceRendered">Service Rendered</label>
                            <select
                                id="serviceRendered"
                                value={serviceRendered}
                                onChange={(e) => setServiceRendered(e.target.value)}
                                required
                                className="form-input"
                            >
                                <option value="" disabled>Select a service</option>
                                {services.map((service, index) => (
                                    <option key={index} value={service}>
                                        {service}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="serviceDescription">Service Description</label>
                            <textarea
                                id="serviceDescription"
                                value={serviceDescription}
                                onChange={(e) => setServiceDescription(e.target.value)}
                                required
                                className="form-input"
                                placeholder="Your service to the world."
                            ></textarea>
                        </div>

                        <div className="form-group">
                            <label htmlFor="currentLocation">Location</label>
                            <input
                                type="text"
                                id="currentLocation"
                                value={currentLocation}
                                onChange={(e) => setCurrentLocation(e.target.value)}
                                required
                                className="form-input"
                                placeholder="e.g., New York, NY"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="businessName">Business Name</label>
                            <input
                                type="text"
                                id="businessName"
                                value={businessName}
                                onChange={(e) => setBusinessName(e.target.value)}
                                className="form-input"
                                placeholder="Your business name"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="businessName">Set your Min service charge</label>
                            <input
                                type="text"
                                id="minUnits"
                                value={minUnits}
                                onChange={(e) => setMinUnits(e.target.value)}
                                className="form-input"
                                placeholder="Lowest amount your business may charge"
                                required
                            />
                        </div>

                        <button type="submit" className="submit-button" disabled={isSubmitting}>
                            {isSubmitting ? 'Submitting...' : 'Submit'}
                        </button>

                        {successMessage && <p className="success-message">{successMessage}</p>}
                    </form>
                </>
            )}
        </div>
    );
};

export default BusinessCardForm;
